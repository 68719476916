import axios from 'axios'
import { stringify } from '../vue_shared/query_params'
import * as Sentry from '@sentry/browser'

enum ApiRequestMethodEnum {
  DELETE = 'delete',
  GET = 'get',
  POST = 'post',
  PUT = 'put'
}

axios.interceptors.response.use(
  // eslint-disable-next-line camelcase
  function (response: { data?: { redirect_to?: string } }) {
    if (response.data?.redirect_to) {
      window.location.href = response.data.redirect_to
    }

    return response
  },
  function (error) {
    return Promise.reject(error)
  }
)

axios.interceptors.request.use(
  function (config) {
    if (!config.headers.common['X-CSRF-Token']) {
      const csrfEl = document.getElementsByName('csrf-token')
      const token =
        csrfEl.length > 0 ? (csrfEl[0] as HTMLMetaElement).content : ``
      const headerCommon = (config.headers && config.headers.common) || {}
      Object.assign(config, {
        headers: {
          ...(config.headers || {}),
          common: {
            ...headerCommon,
            'X-CSRF-Token': token
          }
        }
      })
    }
    if (
      /\/requests$/i.test(config.url) &&
      config.method === ApiRequestMethodEnum.POST
    ) {
      const data = Object.assign({}, config.data, {
        authenticity_token: config.headers.common['X-CSRF-Token']
      })

      Object.assign(config, { data })
    }

    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response?.status === 403 &&
      error.response.data.includes('InvalidAuthenticityToken')
    ) {
      const csrfToken = document
        .querySelector('meta[name="csrf-token"]')
        ?.getAttribute('content')

      Sentry.captureMessage('CSRF Token validation failed on frontend', {
        level: 'error',
        extra: {
          url: error.config.url,
          responseData: error.response.data,
          csrfToken
        }
      })
    }
    return Promise.reject(error)
  }
)

function apiRequest<D, T>(
  method: ApiRequestMethodEnum,
  url: string,
  data?: D
): Promise<T> {
  return new Promise((resolve, reject) => {
    axios({
      method,
      url,
      data
    })
      .then((response) => {
        resolve(response.data as T)
      })
      .catch(reject)
  })
}

export function remove<D>(url: string, data?: D): Promise<void> {
  return apiRequest<D, undefined>(ApiRequestMethodEnum.DELETE, url, data)
}

export function get<T>(url: string, data?: object): Promise<T> {
  if (data) {
    const queryParams = stringify(data)
    return apiRequest<undefined, T>(
      ApiRequestMethodEnum.GET,
      `${url}?${queryParams}`
    )
  } else {
    return apiRequest<undefined, T>(ApiRequestMethodEnum.GET, url)
  }
}

export function post<D, T>(url: string, data: D): Promise<T> {
  return apiRequest<D, T>(ApiRequestMethodEnum.POST, url, data)
}

export function put<D, T>(url: string, data: D): Promise<T> {
  return apiRequest<D, T>(ApiRequestMethodEnum.PUT, url, data)
}
